const getDefaultState = () => {
  return {
    // global
    activeTab: 0,

    // tab general
    tabGeneral: {},

    // tab progress
    tabProgress: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {},

  mutations: {
    SET_ACTIVE_TAB (state, tabIndex) {
      document.body.scrollTop = 0 // safari
      document.documentElement.scrollTop = 0 // chrome, firefox, ie and opera
      state.activeTab = tabIndex
    },
    SET_TAB_GENERAL (state, payload) {
      state.tabGeneral = payload
    },
    SET_TAB_PROGRESS (state, payload) {
      state.tabProgress = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
